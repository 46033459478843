import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children:[
      {
        path: '/',
        name: 'index',
        component: () => import('../components/HomeConter.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
      },
      {
        path: "/lianxi",
        name: 'lianxi',
        component: () => import('../views/PhoneView.vue')
      },
      {
        path: '/tuyou',
        name: 'Tuyou',
        component: () => import('../views/TuyouView.vue'),
      },
      {
        path: '/youxi',
        name: 'Youxi',
        component: () => import('../views/YouxiView.vue'),
      }
    ]
  }

  
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
