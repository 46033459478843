<template>
  <nav>
    <router-view/>
  </nav>
  
</template>

<style>
*{
  margin:0;
  padding:0;
}
a{text-decoration:none;}
ul li{list-style-type:none;}
</style>
