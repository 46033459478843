<template>
  <div class="home">
    <Navhead/>
    <div class="conter">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navhead from '@/components/Navhead.vue'

export default {
  name: 'HomeView',
  components: {
    Navhead,
  }
}
</script>
<style>

</style>
