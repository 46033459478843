<template>
  <div class="hello">
    <div class="logo">
      <img width="30px" height="30px" src="../assets/logo.png" alt="">
      <span>兔友部落_银川兔友科技有限公司</span>
    </div>
    <ul class="nav">
      <router-link v-for="(item,index) in list" :key='index' :to='item.url' >
        <li><span :class="item.url==$route.path ? 'active' : ''">{{ item.name }}</span>
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data(){
    return{
      tab:0,
      list:[
        {name:'首页',url:'/'},
        {name:'案例中心',url:'/about'},
        {name:'联系我们',url:'/lianxi'}
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  display:flex;
  width:100%;
  height:70px;
  background: linear-gradient(to right, #EAEEFF, #E1E7FF);
}
.logo{
  margin-left:5%;
  line-height:75px;
}
.logo img{
  vertical-align:middle;
}
.logo span{
  margin-left:10px;
  color:#0036FF;
}
  .nav{
    display:flex;
    margin-left:300px;
  }
  .nav li{
      margin-left:100px;
      line-height:75px;
      color:#000;
  }
  .active{
      color:#0036FF;
  }
  .dsa{
    color:red;
  }
</style>
